import React from 'react';
import ReactDOM from 'react-dom/client';
import { IconContext } from 'react-icons';
import { ToastContainer } from 'react-toastify';

import './index.css';
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import Layout from 'components/Layout';
import reportWebVitals from './reportWebVitals';
import SharedContextProvider from 'context/SharedContext';

import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <IconContext.Provider value={{ className: "text-gray-800 dark:text-gray-100 text-md" }}>
      <SharedContextProvider>
        <Layout />
        <ToastContainer />
      </SharedContextProvider>
    </IconContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
