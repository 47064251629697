import React from "react";
import { NavLink } from "react-router-dom";

interface IMenuItem {
  onClick?: () => void;
  children?: React.ReactNode;
  path?: string;
  label?: string;
  className?: string;
  onlyIcon?: boolean;
}

const MenuItem = ({
  path,
  label,
  onClick,
  onlyIcon,
  children,
  className,
}: IMenuItem) => {
  const getClass = ({ isActive }: { isActive: boolean }) => {
    let classNames = [
      "flex items-center p-2 text-base font-normal rounded-lg",
      "text-gray-500",
      "dark:text-white",
      "dark:hover:bg-primary-bg",
      "hover:bg-primary-bg",
      "dark:hover:text-primary-500",
      "hover:text-primary-500",
      "cursor-pointer",
      isActive && path ? "text-primary-500 " : "text-gray-400 hover:text-primary-500 ",
      onlyIcon ? "m-auto" : "",
    ];
    if (className) classNames.push(className);
    if (path) classNames.push(isActive ? "bg-primary-bg dark:text-primary-500 text-primary-500" : "");
    return classNames.join(" ");
  };

  const content = (
    <>
      {children}
      {!onlyIcon && <span className={"text-sm whitespace-pre  " + (children ? "ml-3" : "ml-9")}>{label}</span>}
    </>
  );

  return (
    <li className="rounded-md hover:bg-gray-50 dark:hover:bg-gray-600">
      {path ? (
        <NavLink to={path || ""} className={getClass} onClick={() => onClick && onClick()}>
          {content}
        </NavLink>
      ) : (<div className={getClass({ isActive: false })} onClick={() => onClick && onClick()}>
        {content}
      </div>
      )}
    </li>
  );
};

export default MenuItem;
