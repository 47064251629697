import { actionWrapper } from "utilities/functions/wrappers.utils";

import { apiCallChangePassword, apiCallGetManual, apiCallLogin, apiCallRefreshCredentials } from "./auth.api";
import { actionSendSuccessMessage } from "stores/toast.store";
import { ILoginResponse } from "../interfaces/response.interface";

export async function actionLogin(data: { email: string, password: string }): Promise<ILoginResponse> {
  return await actionWrapper(async () => {
    const response = await apiCallLogin(data);
    actionSendSuccessMessage(response.data.message);
    return response;
  });
};

export async function actionRefreshCredentials(token: string): Promise<ILoginResponse> {
  return await actionWrapper(async () => {
    const response = await apiCallRefreshCredentials(token);
    actionSendSuccessMessage(response.data.message);
    return response;
  }, true, false);
};

export async function actionChangePassword(data: { username: string; password: string, newPassword: string }): Promise<ILoginResponse> {
  return await actionWrapper(async () => {
    const response = await apiCallChangePassword(data);
    actionSendSuccessMessage(response.data.message);
    return response;
  });
};

export async function actionGetManual(): Promise<string> {
  return await actionWrapper(async () => {
    const response = await apiCallGetManual();
    actionSendSuccessMessage(response.message ?? '');
    return response.data;
  });
};
