interface IImageProps {
  path: string;
  className?: string;
  alt?: string;
}

const Image = ({ path, className: sCustomClass = "", alt: sAlt = "Image" }: IImageProps ) => {
  if (!path) return <div className={`bg-white ${sCustomClass}`} />;

  return <img className={sCustomClass} src={path} alt={sAlt} />;
};

export default Image;
