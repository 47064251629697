import { create } from 'zustand';

import { IUser } from 'config/interfaces/user.interface';
import { actionChangePassword, actionGetManual, actionLogin, actionRefreshCredentials } from 'pages/auth/actions/auth.actions';

export enum AuthStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

type AuthState = {
  user?: IUser;
  token: string | null;
  status?: AuthStatus;

  manual?: string;
}

type AuthActions = {
  logout: () => void;
  refreshCredentials: (token: string) => void;
  login: (email: string, password: string) => void;
  changePassword: (password: string, newPassword: string) => void;

  getManual: () => void;
  cleanManual: () => void;

  cleanStatus: () => void;
}

const useAuthStore = create<AuthState & AuthActions>((set) => ({
  token: null,
  login: async (email: string, password: string) => {
    const response = await actionLogin({ email, password });
    if (response)
      set({ token: response.data.token, user: response.data.user });
  },
  refreshCredentials: async (token) => {
    const response = await actionRefreshCredentials(token);
    if (response)
      set({ token: response.data.token, user: response.data.user });
    set({ status: response ? AuthStatus.SUCCESS : AuthStatus.ERROR });
  },
  changePassword: async (password: string, newPassword: string) => {
    const state = useAuthStore.getState();
    const response = await actionChangePassword({ newPassword, password, username: state.user?.username ?? '' });
    if (response)
      set({ status: AuthStatus.SUCCESS });
  },
  logout: () => set({ token: null, user: undefined }),

  getManual: async () => {
    const response = await actionGetManual();
    if (response) set({ manual: response });
  },
  cleanManual: () => set(() => ({ manual: undefined })),

  cleanStatus: () => set(() => ({ status: undefined })),
}));

export default useAuthStore;
