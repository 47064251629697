import { create } from 'zustand';

type LoadingState = {
  loading: boolean;
}

type LoadingActions = {
  show: () => void;
  dismiss: () => void;
}

const useLoadingStore = create<LoadingState & LoadingActions>((set) => ({
  loading: false,
  show: () => set({ loading: true }),
  dismiss: () => set({ loading: false }),
}));

export default useLoadingStore;

const { show, dismiss } = useLoadingStore.getState();

export async function loadingWrapper(callback: Function) {
  show();
  const response = await callback();
  dismiss();
  return response;
};
