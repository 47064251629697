import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import { SharedContext } from "context/SharedContext";

const Home = React.lazy(() => import(/*webpackChunkName: "Home" */ "pages/home/Home"));

const Login = React.lazy(() => import(/*webpackChunkName: "Login" */ "pages/auth/LoginPage"));
const ChangePasswordPage = React.lazy(() => import(/*webpackChunkName: "Login" */ "pages/auth/ChangePasswordPage"));

const CountriesList = React.lazy(() => import(/*webpackChunkName: "CountriesList" */ "pages/countries/CountriesList"));

const BanksList = React.lazy(() => import(/*webpackChunkName: "BanksList" */ "pages/banks/BanksList"));

const CategoriesList = React.lazy(() => import(/*webpackChunkName: "CategoriesList" */ "pages/categories/CategoriesList"));

const ProcedureTypesList = React.lazy(() => import(/*webpackChunkName: "ProcedureTypesList" */ "pages/procedure_types/ProcedureTypesList"));
const ProcedureTypesDetails = React.lazy(() => import(/*webpackChunkName: "ProcedureTypesDetails" */ "pages/procedure_types/ProcedureTypesDetails"));

const ProductsList = React.lazy(() => import(/*webpackChunkName: "ProductsList" */ "pages/products/ProductsList"));
const ProductsDetails = React.lazy(() => import(/*webpackChunkName: "ProductsDetails" */ "pages/products/ProductsDetails"));

const StagesList = React.lazy(() => import(/*webpackChunkName: "StagesList" */ "pages/stages/StagesList"));

const ConceptsList = React.lazy(() => import(/*webpackChunkName: "ConceptsList" */ "pages/concepts/ConceptsList"));

const ClientsList = React.lazy(() => import(/*webpackChunkName: "ClientsList" */ "pages/clients/ClientsList"));
const ClientsDetails = React.lazy(() => import(/*webpackChunkName: "ClientsDetails" */ "pages/clients/ClientsDetails"));

const ProvidersList = React.lazy(() => import(/*webpackChunkName: "ProvidersList" */ "pages/providers/ProvidersList"));
const ProvidersDetails = React.lazy(() => import(/*webpackChunkName: "ProvidersDetails" */ "pages/providers/ProvidersDetails"));

const AdministrationMovementsList = React.lazy(() => import(/*webpackChunkName: "AdministrationMovementsList" */ "pages/administration/AdministrationMovementsList"));
const AdministrationMovementsDetails = React.lazy(() => import(/*webpackChunkName: "AdministrationMovementsDetails" */ "pages/administration/AdministrationMovementsDetails"));

const DispatchesList = React.lazy(() => import(/*webpackChunkName: "DispatchesList" */ "pages/dispatches/DispatchesList"));
const DispatchesDetails = React.lazy(() => import(/*webpackChunkName: "DispatchesDetails" */ "pages/dispatches/DispatchesDetails"));

const ShipmentsList = React.lazy(() => import(/*webpackChunkName: "ShipmentList" */ "pages/shipments/ShipmentsList"));
const ShipmentsDetails = React.lazy(() => import(/*webpackChunkName: "ShipmentsDetails" */ "pages/shipments/ShipmentsDetails"));

const UsersList = React.lazy(() => import(/*webpackChunkName: "UsersList" */ "pages/users/UsersList"));
const UsersDetails = React.lazy(() => import(/*webpackChunkName: "UsersDetails" */ "pages/users/UsersDetails"));

const RolesList = React.lazy(() => import(/*webpackChunkName: "RolesList" */ "pages/roles/RolesList"));
const RolesDetails = React.lazy(() => import(/*webpackChunkName: "RolesDetails" */ "pages/roles/RolesDetails"));

const publicRoutes: { key: string; path: string; element: React.ReactNode }[] = [
  { key: "login", path: "/", element: <Login /> },
  { key: "notFound", path: "*", element: <Login /> },
];

export const privateRoutes: { key: string; path: string; element: React.ReactNode }[] = [
  { key: "change-password", path: "/change-password", element: <ChangePasswordPage /> },

  { key: "countries", path: "/countries", element: <CountriesList /> },

  { key: "banks", path: "/banks", element: <BanksList /> },

  { key: "categories", path: "/categories", element: <CategoriesList /> },

  { key: "procedureTypes", path: "/procedure/types", element: <ProcedureTypesList /> },
  { key: "procedureTypesSave", path: "/procedure/types/new", element: <ProcedureTypesDetails /> },
  { key: "procedureTypesDetails", path: "/procedure/types/:id", element: <ProcedureTypesDetails /> },

  { key: "products", path: "/products", element: <ProductsList /> },
  { key: "productsSave", path: "/products/new", element: <ProductsDetails /> },
  { key: "productsDetails", path: "/products/:id", element: <ProductsDetails /> },

  { key: "stages", path: "/stages", element: <StagesList /> },

  { key: "concepts", path: "/concepts", element: <ConceptsList /> },

  { key: "clients", path: "/clients", element: <ClientsList /> },
  { key: "clientsSave", path: "/clients/new", element: <ClientsDetails /> },
  { key: "clientsDetails", path: "/clients/:id", element: <ClientsDetails /> },

  { key: "providers", path: "/providers", element: <ProvidersList /> },
  { key: "providersSave", path: "/providers/new", element: <ProvidersDetails /> },
  { key: "providersDetails", path: "/providers/:id", element: <ProvidersDetails /> },

  { key: "administration", path: "/administration", element: <AdministrationMovementsList /> },
  { key: "administrationSave", path: "/administration/new", element: <AdministrationMovementsDetails /> },
  { key: "administrationDetails", path: "/administration/:id", element: <AdministrationMovementsDetails /> },

  { key: "dispatches", path: "/dispatches", element: <DispatchesList /> },
  { key: "dispatchesSave", path: "/dispatches/new", element: <DispatchesDetails /> },
  { key: "dispatchesDetails", path: "/dispatches/:id", element: <DispatchesDetails /> },

  { key: "shipments", path: "/shipments", element: <ShipmentsList /> },
  { key: "shipmentsSave", path: "/shipments/new", element: <ShipmentsDetails /> },
  { key: "shipmentsDetails", path: "/shipments/:id", element: <ShipmentsDetails /> },

  { key: "users", path: "/users", element: <UsersList /> },
  { key: "usersSave", path: "/users/new", element: <UsersDetails /> },
  { key: "usersDetails", path: "/users/:id", element: <UsersDetails /> },

  { key: "roles", path: "/roles", element: <RolesList /> },
  { key: "rolesSave", path: "/roles/new", element: <RolesDetails /> },
  { key: "rolesDetails", path: "/roles/:id", element: <RolesDetails /> },

  { key: "home", path: "*", element: <Home /> },
];

export function RouteConfig() {
  const { isAuthenticated } = useContext(SharedContext);

  return (
    <Routes>
      {isAuthenticated
        ? privateRoutes.map(oRoute => <Route {...oRoute} key={oRoute.key} />)
        : publicRoutes.map(oRoute => <Route {...oRoute} key={oRoute.key} />)}
    </Routes>
  );
}
