import { Buffer } from "buffer";

export function loginMapper(data: { email: string; password: string }) {
  return {
    UsrUsuario: data.email,
    UsrClave: Buffer.from(data.password).toString("base64")
  }
}

export function changePasswordMapper(data: { username: string; password: string; newPassword: string }) {
  return {
    UsrUsuario: data.username,
    UsrClaveActual: Buffer.from(data.password).toString("base64"),
    UsrClaveNueva: Buffer.from(data.newPassword).toString("base64"),
  }
}