import { loadingWrapper } from "stores/loading.store";
import { actionManageErrorMessage } from "stores/toast.store";

export function actionWrapper(callback: Function, loading = true, handleErrors = true) {
  const wrapper = async () => {
    try {
      return await callback();
    } catch (error) {
      console.error("Error obtenido: ", error);
      if (handleErrors)
        actionManageErrorMessage(error);
    }
  };

  if (loading)
    return loadingWrapper(wrapper);
  return wrapper();
};
