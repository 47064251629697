import React, { useEffect, useState } from "react";

import useLocalStorage from "utilities/hooks/useLocalStorage";
import { StorageKeys } from "config/constants/storage.constants";
import useAuthStore, { AuthStatus } from "stores/auth.store";

interface ISharedContext {
  token: string | null;
  isDarkTheme: boolean;
  onLogout: () => void;
  isAuthenticated: boolean;
  setIsDarkTheme: (value: boolean) => void;
}

export const SharedContext = React.createContext<ISharedContext>({
  token: null,
  isDarkTheme: false,
  onLogout: () => { },
  isAuthenticated: false,
  setIsDarkTheme: () => { },
});

const darkThemeUser = window.matchMedia("(prefers-color-scheme: dark)");

export default function SharedContextProvider({ children }: { children: React.ReactNode }) {
  const { user, refreshCredentials, status, cleanStatus } = useAuthStore();
  const logout = useAuthStore((state) => state.logout);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useLocalStorage(StorageKeys.APP_TOKEN_KEY);
  const [isDarkTheme, setIsDarkTheme] = useLocalStorage(StorageKeys.APP_THEME_KEY, !!darkThemeUser?.matches || false);

  const [, setCalled] = useState(false);

  useEffect(() => {
    setCalled(bValue => {
      if (!bValue && token) refreshCredentials(token);
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === AuthStatus.ERROR) {
      setToken(null);
      setIsAuthenticated(false);
      cleanStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (user?.token || token) {
      setToken(user?.token || token);
      setIsAuthenticated(true);
    } else
      logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onLogout = () => {
    setToken(null);
    setIsAuthenticated(false);
  };

  return (
    <SharedContext.Provider value={{ isAuthenticated, isDarkTheme, setIsDarkTheme, token, onLogout }}>
      {children}
    </SharedContext.Provider>
  );
}
