import { Suspense, useContext, useState } from "react";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

import history from "routes/History";

import Menu from "components/menu/Menu";
import Toast from "components/Toast";
import Loading from "components/Loading";

import useLoadingStore from "stores/loading.store";

import { RouteConfig } from "routes/Router";
import { SharedContext } from "context/SharedContext";

export default function Layout() {
  const loading = useLoadingStore(state => state.loading);
  const { isDarkTheme, isAuthenticated } = useContext(SharedContext);

  const [bIsOpen, setOpen] = useState(false);

  return (
    <main className={isDarkTheme ? "dark" : ""}>
      {/* @ts-expect-error */}
      <HistoryRouter history={history}>
        <Suspense fallback={<Loading open />}>
          <div className="flex bg-gray-50 dark:bg-dark">
            {isAuthenticated && <Menu open={bIsOpen} onChange={setOpen} />}
            <div
              className={
                "w-screen transition-all duration-300 " +
                (isAuthenticated ? (bIsOpen ? "md:pl-64" : "pl-14") : "pl-0")
              }
            >
              <RouteConfig />
            </div>
          </div>
        </Suspense>
        <Loading open={loading} />
        <Toast />
      </HistoryRouter>
    </main>
  );
}
