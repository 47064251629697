import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { ImUsers } from "react-icons/im";
import { IoMdBoat } from "react-icons/io";
import { BsBank2 } from "react-icons/bs";
import { BiUserPin } from "react-icons/bi";
import { RiAdminFill } from "react-icons/ri";
import { CiBoxes, CiTextAlignLeft } from "react-icons/ci";
import { AiOutlineOrderedList } from "react-icons/ai";
import { FiChevronsLeft, FiLogOut, FiMenu, FiType } from "react-icons/fi";
import { MdAdminPanelSettings, MdLockOutline, MdManageAccounts, MdSecurity } from "react-icons/md";
import { FaHome, FaStore, FaMoon, FaSun, FaBox, FaList, FaFlag, FaUsers } from "react-icons/fa";

import MenuItem from "./MenuItem";
import Text from "components/shared/Text";
import Image from "components/shared/Image";
import MenuDropDown from "./MenuDropDown";

import Logo from "assets/images/png/logo.png";
import { Colors } from "config/constants/colors.constants";
import useAuthStore from "stores/auth.store";
import { SharedContext } from "context/SharedContext";

interface IMenuProps {
  open: boolean;
  onChange: (open: boolean) => void;
}

const Menu = ({ open, onChange }: IMenuProps) => {
  const { logout, user, manual, getManual, cleanManual } = useAuthStore();
  const { onLogout, isDarkTheme, setIsDarkTheme } = useContext(SharedContext);

  useEffect(() => {
    if (manual) {
      const aBytes = atob(manual);
      const aBytesNumber = new Array(aBytes.length);
      for (let nCount = 0; nCount < aBytes.length; nCount++)
        aBytesNumber[nCount] = aBytes.charCodeAt(nCount);
      const aBytesArray = new Uint8Array(aBytesNumber);
      const oBlob = new Blob([aBytesArray], { type: "application/pdf" });
      const oBlobURL = URL.createObjectURL(oBlob);
      window.open(oBlobURL, "_blank");
      setTimeout(() => {
        window.URL.revokeObjectURL(oBlobURL);
      }, (10 * 60 * 1000));
      cleanManual();
    }
  }, [cleanManual, manual]);

  const handleLogout = () => {
    onLogout();
    logout();
  };

  function downloadManual() {
    getManual();
  };

  const [active, setOpen] = useState<string | undefined>(undefined);

  const nIconSize = 22;
  const nIconSizeLg = 25;

  return (
    <div className={"fixed h-screen transition-all duration-300 shadow-lg z-40 " + (open ? "w-64" : "w-14")}>
      <div className="h-full flex flex-col bg-gray-50 dark:bg-gray-800">
        <div className={`${open ? "flex" : ""} items-center mb-8 text-center bg-primary-500 dark:bg-primary-800 px-4 py-4`}>
          <span
            onClick={() => onChange(!open)}
            className={`${open ? "flex" : "hidden"} items-center pl-2.5 col-span-5 transition-all cursor-pointer`}
          >
            <div className="h-10 w-10 mr-3 bg-white rounded-lg overflow-hidden flex items-center justify-center">
              <Image path={Logo} />
            </div>
            <div className="flex flex-col">
              <span className="self-center text-lg font-semibold whitespace-nowrap text-white">Montemuiño</span>
              <span className="text-left text-[10px] text-white">{user?.email}</span>
            </div>
          </span>
          {open ? (
            <div className="h-10 w-10 text-center cursor-pointer">
              <FiChevronsLeft
                size={nIconSizeLg}
                className="text-white ml-auto h-full"
                onClick={() => onChange(!open)}
              />
            </div>
          ) : (
            <div className="h-10 text-center cursor-pointer">
              <FiMenu size={nIconSizeLg} className="m-auto h-full text-white" onClick={() => onChange(!open)} />
            </div>
          )}
        </div>
        <ul className="grow px-2 overflow-scroll scrollbar-hide">
          <MenuItem label="Dashboard" path="/" onlyIcon={!open} className="my-2">
            <FaHome size={nIconSize} color={Colors.INHERIT} />
          </MenuItem>
          <MenuDropDown
            open={active}
            title="Gestión"
            onlyIcon={!open}
            onClick={setOpen}
            identifier="management"
            icon={<MdManageAccounts size={nIconSize} color={Colors.INHERIT} />}
          >
            <>
              <MenuItem label="Despachos" path="/dispatches" onlyIcon={!open} className="my-2">
                <FaBox size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Embarques" path="/shipments" onlyIcon={!open} className="my-2">
                <IoMdBoat size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Administración" path="/administration" onlyIcon={!open} className="my-2">
                <MdAdminPanelSettings size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
          <MenuDropDown
            open={active}
            onClick={setOpen}
            onlyIcon={!open}
            title="Tablas Básicas"
            identifier="tables"
            icon={<AiOutlineOrderedList size={nIconSize} color={Colors.INHERIT} />}
          >
            <>
              <MenuItem label="Clientes" path="/clients" onlyIcon={!open} className="my-2">
                <ImUsers size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Proveedores" path="/providers" onlyIcon={!open} className="my-2">
                <FaStore size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Productos" path="/products" onlyIcon={!open} className="my-2">
                <CiBoxes size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Rubros" path="/categories" onlyIcon={!open} className="my-2">
                <FaList size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Etapas" path="/stages" onlyIcon={!open} className="my-2">
                <AiOutlineOrderedList size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Bancos" path="/banks" onlyIcon={!open} className="my-2">
                <BsBank2 size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Países" path="/countries" onlyIcon={!open} className="my-2">
                <FaFlag size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Tipos de Trámite" path="/procedure/types" onlyIcon={!open} className="my-2">
                <FiType size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Conceptos" path="/concepts" onlyIcon={!open} className="my-2">
                <CiTextAlignLeft size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
          <MenuDropDown
            open={active}
            title="Seguridad"
            onlyIcon={!open}
            onClick={setOpen}
            identifier="security"
            icon={<MdSecurity size={nIconSize} color={Colors.INHERIT} />}
          >
            <>
              <MenuItem label="Usuarios" path="/users" onlyIcon={!open} className="my-2">
                <FaUsers size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
              <MenuItem label="Roles" path="/roles" onlyIcon={!open} className="my-2">
                <RiAdminFill size={nIconSize} color={Colors.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
        </ul>
        <ul className="m-2">
          <MenuItem
            className="p-2"
            onlyIcon={!open}
            onClick={downloadManual}
            label="Manual de usuario"
          >
            <BiUserPin size={nIconSize} color={Colors.INHERIT} />
          </MenuItem>
          <MenuItem label="Cerrar sesión" onlyIcon={!open} onClick={handleLogout} className="p-2">
            <FiLogOut size={nIconSize} color={Colors.INHERIT} />
          </MenuItem>
        </ul>
        <div className={`w-full flex ${open ? "" : "flex-wrap justify-center"} items-center gap-3 text-white ` + (open ? "border-t-2 px-4 py-4" : "px-1 py-4")}>
          <div className={"align-middle ml-1 overflow-hidden " + (!open && "hidden")}>
            <Text className="whitespace-pre text-ellipsis overflow-hidden">{user?.name}</Text>
            <Text color={"text-gray-400"} size={"text-xs"} className="whitespace-pre text-ellipsis overflow-hidden">
              {user?.email}
            </Text>
          </div>

          <NavLink
            to="/change-password"
            className={`${open ? "ml-auto" : ""} bg-gray-200 dark:bg-gray-50 text-gray-500 dark:text-gray-400 hover:bg-gray-300 dark:hover:bg-gray-200 focus:outline-none rounded-md text-sm p-2.5`}
          >
            <MdLockOutline className="w-5 h-5 fill-gray-800 dark:fill-gray-800" />
          </NavLink>
          <button
            type="button"
            onClick={() => setIsDarkTheme(!isDarkTheme)}
            className="bg-gray-800 dark:bg-gray-50 text-gray-500 dark:text-gray-400 hover:bg-gray-600 dark:hover:bg-gray-200 focus:outline-none rounded-md text-sm p-2.5"
          >
            <FaMoon className={`${isDarkTheme && "hidden"} w-5 h-5 fill-gray-50`} />
            <FaSun className={`${!isDarkTheme && "hidden"} w-5 h-5 fill-yellow-500`} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Menu;
