import { IChangePasswordResponse, IGetManualResponse, ILoginResponse } from "../interfaces/response.interface";
import { IChangePasswordApiResponse, IGetManualApiResponse, ILoginApiResponse } from "../interfaces/auth.api.interfaces";

export function loginUnmapped(data: ILoginApiResponse): ILoginResponse {
  return {
    data: {
      message: data.message,
      token: data.data.usrToken,
      user: {
        id: data.data.usrId,
        name: data.data.usrNombreCompleto,
        email: data.data.usrCorreoUsuario,
        username: data.data.usrUsuario,
        token: data.data.usrToken,
      }
    }
  }
}

export function changePasswordUnmapped(data: IChangePasswordApiResponse): IChangePasswordResponse {
  return {
    data: {
      message: data.message,
    }
  }
}

export function getManualUnmapped(data: IGetManualApiResponse): IGetManualResponse {
  return { data: data.data, message: data.message };
}