import React from "react";

import { TextTypes } from "config/constants/text.constants";

interface ITextProps {
  children?: string | string[] | number;
  size?: string;
  className?: string;
  color?: string;
  bold?: boolean;
  italic?: boolean;
  style?: number;
  simple?: boolean;
}

const Text = ({
  size,
  bold,
  color,
  simple,
  italic,
  children,
  className,
  style = TextTypes.DEFAULT,
}: ITextProps) => {
  if (!children) return null;
  if (simple) return <p className={className}>{children}</p>;

  let classNames = [];

  switch (style) {
    case TextTypes.LIGHT:
      classNames = ["text-white dark:text-gray-200"];
      break;
    case TextTypes.DARK:
      classNames = ["text-gray-800 dark:text-gray-400"];
      break;
    case TextTypes.DEFAULT:
    default:
      classNames = ["text-gray-500 dark:text-gray-300"];
      break;
  }

  if (color) classNames[0] = color;
  if (size) classNames.push(size);
  if (italic) classNames.push("italic");
  if (bold) classNames.push("font-bold");
  if (className) classNames.push(className);

  return <p className={classNames.join(" ")}>{children}</p>;
};

export default Text;
