import axiosInstance from "config/axios/config.axios";
import { IChangePasswordResponse, IGetManualResponse, ILoginResponse } from "../interfaces/response.interface";
import { changePasswordMapper, loginMapper } from "./auth.mapper";
import { changePasswordUnmapped, getManualUnmapped, loginUnmapped } from "./auth.unmapper";

export async function apiCallLogin(data: { email: string, password: string }): Promise<ILoginResponse> {
  return loginUnmapped(await axiosInstance.post("seguridad/usuarios/login", loginMapper(data)));
};

export async function apiCallRefreshCredentials(token: string): Promise<ILoginResponse> {
  const body = new FormData();
  body.append("token", token);
  return loginUnmapped(await axiosInstance.post("seguridad/usuarios/control", body));
};

export async function apiCallChangePassword(data: { username: string; password: string, newPassword: string }): Promise<IChangePasswordResponse> {
  return changePasswordUnmapped(await axiosInstance.post("seguridad/usuarios/cambioclave", changePasswordMapper(data)));
};

export async function apiCallGetManual(): Promise<IGetManualResponse> {
  return getManualUnmapped(await axiosInstance.get("herramientas/manualdeusuario"));
};
