import { TypeOptions } from 'react-toastify';
import { create } from 'zustand';

export enum ToastTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

type ToastState = {
  message?: string;
  type?: TypeOptions;
}

type ToastActions = {
  clean: () => void;
  sendMessage: (message: string, type?: ToastTypes) => void;
}

const useToastStore = create<ToastState & ToastActions>((set) => ({
  sendMessage: (message, type) => set({ message, type: type }),
  clean: () => set({ message: undefined, type: undefined }),
}));

export default useToastStore;

const sendMessage = useToastStore.getState().sendMessage;

export function actionManageErrorMessage(error: any) {
  let message = "Ha ocurrido un error, por favor intentelo de nuevo más tarde";
  let type = ToastTypes.ERROR;
  if (typeof error === "string") {
    message = error;
    if (error === "Network Error") message = "Conexión de red inestable";
    if (error === "Request failed with status code 404") {
      type = ToastTypes.WARNING;
      message = "Método no implementado";
    }
    return sendMessage(message, type);
  }
  if (error?.data?.message) return sendMessage(error?.data?.message, type);
  return sendMessage(message, type);
}

export function actionSendSuccessMessage(message: string) {
  sendMessage(message, ToastTypes.SUCCESS);
};
