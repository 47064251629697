import { useContext, useEffect } from "react";
import { toast, Zoom, TypeOptions, ToastPosition } from "react-toastify";

import useToastStore from "stores/toast.store";
import { SharedContext } from "context/SharedContext";

const CONFIG_MESSAGE = {
  position: "bottom-right" as ToastPosition,
  autoClose: 5000,
  // icon: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const Toast = () => {
  const { type, message, clean } = useToastStore();
  const { isDarkTheme } = useContext(SharedContext);

  useEffect(() => {
    if (message) {
      toast(message, {
        ...CONFIG_MESSAGE,
        transition: Zoom,
        theme: isDarkTheme ? "dark" : "light",
        type: (type || 'error') as TypeOptions,
      });
      clean();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, type]);

  return null;
};

export default Toast;
