import axios from "axios";
import promise from "promise";

import history from "routes/History";

import { StorageKeys } from "config/constants/storage.constants";
import { API_BASE_URL } from "config/constants/system.constants";
import { getLocalStorageItem } from "utilities/hooks/useLocalStorage";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  async function(config) {
    let token = getLocalStorageItem(StorageKeys.APP_TOKEN_KEY);
    if (token && config.method !== "OPTIONS") {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    return promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    return response.data?.value || response.data;
  },
  error => {
    const sMessage = error?.response?.data?.value?.message || error?.response?.data || error.message;
    if (error?.response?.status === 403) {
      history.replace("/");
      return promise.reject(sMessage || "No cuenta con permisos necesarios para esta tarea");
    }
    return promise.reject(sMessage);
  },
);

export default axiosInstance;
