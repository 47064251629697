import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { Colors } from "config/constants/colors.constants";

const Loading = ({ open: bIsOpen = false }) => {
  if (!bIsOpen) return null;

  return (
    <div className="w-screen h-screen top-0 left-0 flex fixed z-50 bg-gray-900 bg-opacity-60 items-center">
      <AiOutlineLoading3Quarters
        size={35}
        color={Colors.PRIMARY_COLOR}
        className="m-auto animate-spin"
      />
    </div>
  );
};

export default Loading;
